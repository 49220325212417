import { render, staticRenderFns } from "./BboxUsecases.vue?vue&type=template&id=5ce2cc88&scoped=true&"
import script from "./BboxUsecases.vue?vue&type=script&lang=js&"
export * from "./BboxUsecases.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ce2cc88",
  null
  
)

export default component.exports