<template>
  <v-container grid-list-lg>
    <v-row no-gutters center>
      <v-spacer />
      <v-col
        cols="2"
        sm="2"
        md="2"
      >
        <v-card
          class="pa-0"
          outlined
          color="transparent"
        >
        <v-img src="../assets/logo.png" width="120px" center>
        </v-img>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
  <!-- <v-container grid-list-lg>
    <v-spacer />
    <v-flex xs12 sm12 md6>
      <v-layout justify-center align-center column pa-1>
        <v-img src="../assets/logo.png" width="120px">
        </v-img>
      </v-layout>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <v-layout justify-center align-center column pa-1>
        <div class="display-2 font-weight-black black--text text-xs-center">Health</div>
        <div class="display-2 font-weight-black black--text text-xs-center mb-3">Oracle</div>
        <div class="display-2 font-weight-bold black--text text-xs-center">Protocol</div>
      </v-layout>
    </v-flex>
    <v-spacer />
  </v-container> -->
</template>

<script>
export default {
  name: 'TruthPublic'
}
</script>

<style scoped>
</style>
